<template>
  <v-card-text class="mt-5 mb-3 px-md-12 px-sm-5">
    <app-form ref="form" :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"
      footerClass="col-12 text-center mt-5" disableCancelButton />
  </v-card-text>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'UpdatePublicProfile',
  data() {
    return {
      submitButton: {
        label: this.$t('actions.save')
      },
      fields: {
        firstName: {
          type: 'text',
          key: 'firstName',
          value: '',
          rules: [{name: 'required'}],
          label: this.$t('models.user.attributes.firstName'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        lastName: {
          type: 'text',
          key: 'lastName',
          value: '',
          rules: [{name: 'required'}],
          label: this.$t('models.user.attributes.lastName'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        birthdate: {
          type: 'yearDatePicker',
          key: 'birthdate',
          value: '',
          label: this.$t('models.user.attributes.birthday'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        address: {
          type: 'text',
          key: 'address',
          value: '',
          label: this.$t('models.user.attributes.address'),
          icon: 'mdi-map-marker',
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        phoneNumber: {
          type: 'phone',
          key: 'phoneNumber',
          phoneMode: 'international',
          value: '',
          label: this.$t('models.user.attributes.phoneNumber'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        emergencyNumber: {
          type: 'phone',
          phoneMode: 'international',
          key: 'emergencyNumber',
          value: '',
          label: this.$t('models.user.attributes.emergencyNumber'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        avatar: {
          type: 'file',
          showImage: true,
          key: 'avatar',
          clearable: true,
          showSize: true,
          accept: 'image/*',
          pastFile: '',
          icon: 'mdi-camera',
          label: this.$t('models.user.attributes.avatar'),
          value: null,
          classes: 'col-12 py-0 mt-5',
          serverError: []
        },
        bio: {
          type: 'textarea',
          key: 'bio',
          value: '',
          label: this.$t('models.user.attributes.bio'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0 mb-5 mt-5',
          serverError: []
        },
        linkedIn: {
          type: 'text',
          key: 'socialMediaLinkedin',
          value: this.getLink('linkedin') || 'https://linkedin.com/in/',
          icon: 'la-linkedin-square',
          label: this.$t('models.user.attributes.linkedin'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        facebook: {
          type: 'text',
          key: 'socialMediaFacebook',
          value: this.getLink('facebook') || 'https://facebook.com/',
          icon: 'la-facebook-square',
          label: this.$t('models.user.attributes.facebook'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        twitter: {
          type: 'text',
          key: 'socialMediaTwitter',
          value: this.getLink('twitter') || 'https://twitter.com/',
          icon: 'la-twitter-square',
          label: this.$t('models.user.attributes.twitter'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        },
        github: {
          type: 'text',
          key: 'socialMediaGithub',
          value: this.getLink('github') || 'https://github.com/',
          icon: 'la-github-square',
          label: this.$t('models.user.attributes.github'),
          classes: 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-0',
          serverError: []
        }
      }
    };
  },
  computed: {...mapGetters(['user'])},
  methods: {
    ...mapActions(['getUser', 'updateUser']),
    handleSubmit(data) {
      const userData = new FormData();
      for (const key in data) {
        if (data[key]) {
          if (key === 'avatar') {
            if (data[key]) {
              userData.append(key, data[key][0]);
            }
          } else if (key.includes('social_media')) {
            const link = JSON.stringify({platform: key.replace('social_media_', ''), link: data[key]});
            if (link) {
              userData.append('social_media_links[]', link);
            }
          } else {
            userData.append(key, data[key]);
          }
        }
      }
      this.updateUser({userId: this.$route.params.uuid, userData: userData}).then((response) => {
        if (!this.successCode(response.status)) {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    },
    getLink(platform) {
      if (this.user && this.user.links) {
        const socialMediaLink = this.user.links.find((link) => link.platform === platform);
        return socialMediaLink ? socialMediaLink.link : null;
      }
    }
  },
  created() {
    this.getUser({uuid: this.$route.params.uuid}).then(() => {
      ['address', 'birthdate', 'phoneNumber', 'emergencyNumber', 'bio', 'lastName', 'firstName'].forEach((field) => {
        this.fields[field].value = this.user[field];
      });
      this.fields.avatar.pastFile = this.user.avatarFilename;
    });
  }
};
</script>
